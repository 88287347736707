<template>
    <el-container style="height: 100vh">
        <el-aside v-if="isShow" :style="{width:!isCollapse?'210px':'65px'}">
            <left></left>
        </el-aside>
        <el-container>
            <el-header v-if="isShow" class="index-header" style="height: 61px;">
                <navigation></navigation>
            </el-header>
            <el-main style="background-color: #F8F8F8;">
                <!-- 面包屑导航 -->
                <el-breadcrumb class="button_border"
                               v-show="isShow"
                               v-if="currentRouterName.length!==0"
                               separator-class="el-icon-arrow-right"
                               style="height: 60px;line-height: 60px;background-color: white;padding-left: 20px;font-size: 16px;">
                    <el-breadcrumb-item v-for="item in currentRouterName" :key="item">{{ item }}</el-breadcrumb-item>
                </el-breadcrumb>
                <router-view ref="routerView" :style="{'margin':!isShow?'0px':'10px'}"></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import Left from "@/components/layout/Left";
import Navigation from "@/components/layout/Navigation";
import Bus from "@/utils/Bus";
import screenfull from 'screenfull';

export default {
    components: {Navigation, Left},
    data() {
        return {
            isCollapse: false,
            screenfull: {},
            isShow: true
        }
    },
    computed: {
        currentRouterName() {
            if (!this.$route.name || this.$route.name === "首页") {
                return [];
            }
            return this.$route.name.split(";");
        }
    },
    mounted() {
        Bus.$on('collapsed', content => {
            this.isCollapse = content;
        });
        Bus.$on('fullScreen', index => {
            if (index === 1) {
                this.isShow = true;
            } else {
                this.isShow = false;
            }
        });


    },
    beforeDestory() {
        Bus.$off('collapsed');
        Bus.$off('fullScreen');
    },
    methods: {
        // 获取当前路由名称
        toggleFullScreen() {
            if (!screenfull.isFullscreen) {
                if (this.$refs.routerView) {
                    screenfull.request(this.$refs.routerView);
                }
            } else {
                screenfull.exit();
            }
        }
    }
}
</script>

<style scoped>
.index-header,
.index-main {
    padding: 0;

}

/deep/ .el-main {
    padding: 0;
}
</style>
