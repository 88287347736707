<template>
    <div style="display: flex;flex-direction: column;height: 100vh;">
        <div style="height: 80px;line-height: 80px;box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
            display: flex;justify-content: space-between;align-items: center">
            <div style="display: flex">
                <div style="font-size: 26px;margin-left: 50px;color: #607d8b;">
                    智能行情分析系统
                </div>
                <div>
                    <el-divider direction="vertical"></el-divider>
                </div>
                <div style="margin-left: 20px;color: #607d8b;font-size: 18px;">
                    注册新账号
                </div>
            </div>
            <div style="display: flex;margin-right: 50px;" class="top_button" @click="goLogin">
                <el-link style="font-size: 16px;" type="success" :underline="false">返回登录</el-link>
            </div>
        </div>
        <div style="flex: 1;background-color: #F0F0F0;">
            <div style="width: 70%;background-color: white;margin: 50px auto 0;padding: 20px 50px 30px 50px">
                <div>
                    <h2 style="color: #607d8b;">注册新账号</h2>
                </div>

                <div>
                    <el-steps :active="active" finish-status="success" simple style="margin-top: 20px">
                        <el-step title="登录信息"></el-step>
                        <el-step title="基本信息"></el-step>
                        <el-step title="完成注册"></el-step>
                    </el-steps>

                    <div style="height: 50px;"></div>
                    <div style="width: 50%;margin-left: 25%;">
                        <el-form :model="data" :rules="rules" ref="data" label-width="80px">
                            <div v-if="active===0">
                                <el-form-item label="登录名称" prop="userName">
                                    <el-input class="el_input" v-model="data.userName" maxlength="30"></el-input>
                                </el-form-item>
                                <el-form-item label="登录密码" prop="password">
                                    <el-input class="el_input" v-model="data.password" maxlength="20"></el-input>
                                </el-form-item>
                            </div>
                            <div v-if="active===1">
                                <el-form-item label="昵称" prop="nickName">
                                    <el-input class="el_input" maxlength="10" v-model="data.nickName"></el-input>
                                </el-form-item>
                                <el-form-item label="手机号" prop="phone">
                                    <el-input class="el_input" maxlength="11" v-model="data.phone"></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                        <div v-if="active===2">
                        </div>

                        <div style="width:50%;margin-left:15%;">
                            <el-button v-if="active===1" style="" type="success" @click="btnLast('data')">
                                上一步
                            </el-button>
                            <el-button style="" type="success" @click="btnNext('data')">
                                {{ active === 0 ? "下一步" : active === 1 ? "完成注册" : "立即登录" }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {reqRegisterUser} from "@/api/base/Users";

export default {
    name: "Register",
    created() {
    },
    methods: {
        goLogin() {
            this.$router.push("/login");
        },
        goIndex() {
            this.$router.push("/");
        },
        async btnLast() {
            this.active--;
        },
        async btnNext(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if (this.active === 2) {
                        this.goLogin();
                    }
                    if (this.active === 1) {
                        //注册
                        this.$refs[formName].validate(async (valid) => {
                            if (valid) {
                                let {code, msg} = await reqRegisterUser(this.data);
                                this.$message.success(msg);
                            } else {
                                return false;
                            }
                        });
                    }
                    if (this.active < 2) {
                        this.active++;
                    }
                }
            });
        },
    },
    data() {
        return {
            active: 0,
            total: 0,
            dataList: [],
            query: {},
            data: {
                userName: "",
                phone: "",
                nickName: "",
                password: "",
                avatar: "",
                roleId:2,
            },
            rules: {
                userName: [
                    {required: true, message: '请输入用户名', trigger: 'blur'}
                ],
                phone: [
                    {
                        required: true, message: '请输入手机号', trigger: 'blur'
                    }],
                nickName: [
                    {
                        required: true, message: '请输入昵称', trigger: 'blur'
                    }],
                password: [
                    {
                        required: true, message: '请输入密码', trigger: 'blur'
                    }],
            }
        }
    }
}
</script>
<style scoped lang="scss">
.content_div {
    justify-content: center;
    width: 25%;
    position: absolute;
    top: 30%;
    left: 35%;
}

.top_button {
    cursor: pointer;
}

.body_div {
    //background-image: url("@/assets/bg.jpg");
    width: 100%;
    height: 100vh;
    background-color: #92a8a9;
}

.top_card {
    background-color: #77b9be;
    color: white;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
}

.el_input {
    width: 300px;
}
</style>
