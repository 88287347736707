import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import * as echarts from 'echarts';
import "element-ui/lib/theme-chalk/index.css";
Vue.prototype.$echarts = echarts
import iconPicker from 'vue-fontawesome-elementui-icon-picker' // elementui图标库
Vue.use(iconPicker)

import VueParticles from 'vue-particles'
Vue.use(VueParticles)

import {VueJsonp} from 'vue-jsonp';   //注意：这里要加花括号
Vue.use(VueJsonp);

import 'default-passive-events'

new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    },
}).$mount('#app')
