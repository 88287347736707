<template>
    <div style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12);">
        <div style="display: flex;justify-content: space-between;align-items: center;padding: 0 10px;">
            <div>
                <i @click="toggle(!collapsed)" v-if="!collapsed" style="font-size: 34px;" class="el-icon-s-fold"></i>
                <i @click="toggle(collapsed)" v-if="collapsed" style="font-size: 34px;" class="el-icon-s-unfold"></i>
            </div>
            <div style="display:flex;align-items: center;">
<!--                <div style="margin-top:5px;width: 45px;border: 0px solid red;cursor: pointer;" @click="btnMsg">-->
<!--                    <el-badge :value="12" class="item">-->
<!--                        <i class="el-icon-bell" style="font-size: 22px;"></i>-->
<!--                    </el-badge>-->
<!--                </div>-->
                <div style="border: 0px solid red;">
                    <el-menu style="border-bottom: 0;padding-left: -15px;" default-active="2" class="el-menu-demo" mode="horizontal">
                        <el-submenu index="2">
                            <template slot="title"><span style="color: #606266;font-size: 18px;">{{ loginName }}</span></template>
                            <el-menu-item @click="btnInfo" index="2-1">个人中心</el-menu-item>
                            <el-menu-item @click="logout" index="2-2">退出登录</el-menu-item>
                        </el-submenu>
                    </el-menu>
                </div>
            </div>
        </div>

        <el-drawer
            :size="width>900?'':'100%'"
            title="新消息"
            :visible.sync="isShowDrawer"
            direction="rtl"
            :before-close="handleClose">
            <el-divider style="margin:0"></el-divider>
            <div v-for="item in dataList" :key="item.id"
                 style="font-size: 18px;display: flex;justify-content: space-between;align-items: center;
                 padding:0 20px;border-bottom: 2px solid #F0F0F0;background-color: white;"
                 class="message_list_div">
                <div>
                    <el-image
                        style="width: 50px; height: 50px;border-radius: 50%"
                        src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                        fit="cover"></el-image>
                </div>
                <div style="margin-left: 20px;flex: 1">
                    <div style="font-size: 18px;color: #333;margin-top:20px;">{{ item.title }}</div>
                    <div style="font-size: 14px;"><p>{{ item.content.length > 20 ? item.content.substring(0, 20) + "..." : item.content }}</p></div>
                </div>
                <div style="color: #999;">{{ item.createTime.substring(0, 10) }}</div>
            </div>

            <div
                style="background: #fff;position: fixed;bottom: 0;border-top: 2px solid #F0F0F0;width: 100%;height: 60px;">
                <div style="margin-left: 50px;margin-top:10px;">
                    <el-button type="primary" @click="jump">消息中心</el-button>
                    <el-button @click="btnRead">一键已读</el-button>
                </div>
            </div>

        </el-drawer>
    </div>
</template>
<script>
import {removeLeftMenu, removeToken} from "@/utils/SessionStorage";
import Bus from "@/utils/Bus";
import {reqInsertSysUsers, reqUserInfoLogout} from "@/api/base/Users";

const messageList = [
    {id: 1, title: "网站升级公告", createTime: "2024-08-30 10:00:00", content: "网站升级公告网站升级公告网站升级公告网站升级公告网站升级公告"},
    {id: 2, title: "网站升级公告", createTime: "2024-08-30 10:00:00", content: "网站升级公告网站升级公告网站升级公告网站升级公告网站升级公告"},
    {id: 3, title: "网站升级公告", createTime: "2024-08-30 10:00:00", content: "网站升级公告网站升级公告网站升级公告网站升级公告网站升级公告"},
]
export default {
    name: "Navigation",
    created() {
        this.loginName = sessionStorage.getItem("loginName");
    },
    mounted() {
        window.onresize = () => {
            this.width = window.innerWidth;
        }
    },
    data() {
        return {
            collapsed: false,
            loginName: "",
            isShowDrawer: false,
            dataList: messageList,
            width: window.innerWidth,
        }
    },
    methods: {
        // 切换显示
        toggle(showtype) {
            this.collapsed = !this.collapsed
            //this.$message.success(this.collapsed);
            Bus.$emit('collapsed', this.collapsed)
        },
        async logout() {
            await reqUserInfoLogout({});
            removeToken();
            removeLeftMenu();
            sessionStorage.removeItem("loginName");
            this.$router.push("/login");
        },
        btnInfo() {
            this.$router.push("/system/info");
        },
        btnMsg() {
            this.isShowDrawer = true;
        },
        handleClose() {
            this.isShowDrawer = false;
        },
        jump() {
            this.$message.success("跳转消息中心");
        },
        btnRead() {
            this.$message.success("一键已读");
        },
    }
}
</script>

<style scoped lang="scss">
/deep/ .el-divider--horizontal {
    margin: 0
}

.message_list_div {
    cursor: pointer;

    &:hover {
        background-color: #ecf5ff;
    }
}

/deep/ .rtl {
    font-size: 24px;
    color: #607d8b;
}
</style>
