import Vue from "vue";
import VueRouter from "vue-router";

// 路由静态加载 加载必要的页面级别组件[第一眼就要看到的:login && layout]
// 静态加载目的 加快首批渲染速度 提升用户体验感
// @代表src目录绝对路径
import Login from "@/views/Login.vue"; //静态加载
import Layout from "@/components/layout/Index";
import {getToken, setLeftMenu} from "@/utils/SessionStorage";
import {reqListLeftSysMenu} from "@/api/base/Menu";
import Register from "@/views/Register.vue";
import Bus from "@/utils/Bus";

// 控制无限点击面包屑报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter);

//静态路由
const routes = [// 初始化重定向
    {path: "/", component: Login},
    {path: "/login", component: Login},
    {path: "/register", component: Register}];

// 创建路由实例 导入配置项
const router = new VueRouter({
    routes, mode: "history",
});

let isToken = true
router.beforeEach(async (to, from, next) => {
    let token = getToken();
    if (to.path === "/login" && token === null) {
        isToken = true;
    }
    //如果是从首页到其他页面，则通知关闭通讯系统
    if(from.path==="/home/index"){
        Bus.$emit("closeWebSocket");
    }
    console.log();
    if (token) {
        //获取路由并加载
        if (isToken) {
            //从后台获取到路由数据
            let menuData = await reqListLeftSysMenu({});
            let result = assertRouter(menuData.data);
            setLeftMenu(JSON.stringify(menuData.data))
            let test = [{
                path: "/home", component: Layout, children: result
            }];
            router.addRoutes(test);
            // result.forEach(item => {
            //     router.addRoute(item)
            // })
            //console.log(router.options.routes);
            isToken = false;
            next({
                ...to, // next({ ...to })的目的,是保证路由添加完了再进入页面 (可以理解为重进一次)
                replace: true, // 重进一次, 不保留重复历史
            })
        }
    }
    next();
});


function assertRouter(data) {
    //一级菜单处理
    let result = [];
    data.forEach(item => {
        item.children.forEach(item1 => {
            let childObj = {
                name: item.title + ";" + item1.title, path: "/" + item.path + "/" + item1.path, component: () => import(`@/views${item1.component}`),
            };
            result.push(childObj);
        })
    })
    //添加首页
    result.push({
        path: "/home/index", component: () => import("@/views/index/Index.vue"), name: "首页",
    });
    return result;
}

// 导出配置好的路由实例对象
export default router;
