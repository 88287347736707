import request from '@/utils/request'

const preUrl = "/sysMenu";

export const reqListSysMenu = (data) => {
    return request({
        url: preUrl + '/list', method: 'post', data
    })
}

export const reqListLeftSysMenu = (params) => {
    return request({
        url: preUrl + '/listLeftMenu', method: 'get', params
    })
}
export const reqInsertSysMenu = (data) => {
    return request({
        url: preUrl + '/insert', method: 'post', data
    })
}
export const reqUpdateSysMenu = (data) => {
    return request({
        url: preUrl + '/update', method: 'post', data
    })
}
export const reqDeleteSysMenu = (params) => {
    return request({
        url: preUrl + '/delete', method: 'get', params
    })
}
