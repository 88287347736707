import axios from 'axios'
import {getToken, removeLeftMenu, removeRouter, removeToken} from "@/utils/SessionStorage";
import {Message} from 'element-ui'
import global from "@/api/global";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: global.base_url, // 超时
    timeout: 20000
})
//request拦截器 添加一个请求拦截器
service.interceptors.request.use(function (config) {
    let token = getToken();
    if (token) {
        // 设置请求头
        config.headers.token = token
    }
    return config
}, function (error) {
    //如果请求出错在此执行某些操作
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        if (res.data.code !== 0) {
            let error =  res.data.msg;
            Message({message: error, type: 'error'})
            if (res.data.code===401){
                removeToken();
                removeLeftMenu();
                removeRouter();
                window.location.href = '/login';
            }
            return Promise.reject(new Error(error))
        }
        return res.data;
    },
    error => {
        let {message} = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({message: message, type: 'error', duration: 5 * 1000})
        return Promise.reject(error)
    }
)

export default service
