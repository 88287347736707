import request from '@/utils/request'

const preUrl = "/sysUsers";
export const reqRegisterUser = (data) => {
    return request({
        url: '/sysUsers/insert', method: 'post', data
    })
}
export const reqUsersLogin = (data) => {
    return request({
        url: '/sysUsers/login', method: 'post', data
    })
}

export const reqGetUserInfo = (params) => {
    return request({
        url: preUrl + '/info', method: 'get', params
    })
}
export const reqUserInfoLogout = (params) => {
    return request({
        url: preUrl + '/logout', method: 'get', params
    })
}

export const reqListUserInfo = (data) => {
    return request({
        url: preUrl + '/list', method: 'post', data
    })
}
export const reqDeleteUserInfo = (params) => {
    return request({
        url: preUrl + '/delete', method: 'get', params
    })
}
export const reqListAllUsers = (params) => {
    return request({
        url: preUrl + '/listAll', method: 'get', params
    })
}

export const reqInsertSysUsers = (data) => {
    return request({
        url: preUrl + '/insert', method: 'post', data
    })
}
export const reqUpdateSysUsers = (data) => {
    return request({
        url: preUrl + '/update', method: 'post', data
    })
}
export const reqUpdatePwd = (data) => {
    return request({
        url: preUrl + '/updatePwd', method: 'post', data
    })
}
export const reqUpdateUsersSwitch = (params) => {
    return request({
        url: preUrl + '/updateSwitch', method: 'get', params
    })
}