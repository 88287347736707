<template>
    <div style="height: 100%;">
        <el-menu style="height: 100%;"
                 class="el-menu-vertical-demo"
                 :collapse="isCollapse"
                 :default-active="defaultActive"
                 @select="handleSelect"
        >
            <el-menu-item index="home/index">
                <i class="el-icon-house"></i>
                <span slot="title">首页</span>
            </el-menu-item>

            <el-submenu v-for="item in menuDataList" :index="item.path" :key="item.id">
                <template slot="title">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </template>
                <el-menu-item v-for="(child,index) in item.children" :key="child.id" :index="child.path">
                    <i :class="child.icon"></i>
                    {{ child.title }}
                </el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
</template>
<script>
import {getLeftMenu} from "@/utils/SessionStorage";
import {getWindowHeight} from "@/utils/GetWindowUtil";
import Bus from "@/utils/Bus";

export default {
    name: "Left",
    created() {
        this.menuDataList = JSON.parse(getLeftMenu());
        this.windowHeight = getWindowHeight();
        let defaultActive = sessionStorage.getItem("default_active");
        this.defaultActive = (defaultActive) ? defaultActive : "home/index";
    },
    mounted() {
        Bus.$on('collapsed', content => {
            this.isCollapse = content;
        });
        Bus.$on('refreshLeftMenu', (url, path) => {
            this.handleSelect(url,path.split("/"));
        })
    },
    beforeDestory() {
        Bus.$off('collapsed');
    },
    data() {
        return {
            menuDataList: [],
            windowHeight: 0,
            isCollapse: false,
            defaultActive: null,
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            this.defaultActive = key;
            sessionStorage.setItem("default_active", key)
            let jumpPath = "";
            for (const element of keyPath) {
                jumpPath += "/" + element;
            }
            this.$router.push(jumpPath);
        },
    }
}
</script>
<style scoped lang="scss">
//:deep(.el-menu) {
//    .el-menu-item {
//        font-size: 14px;
//        color: 灰色;
//        font-weight: 600;
//        &:hover {
//            color: 白色;
//            background-color: 红色;
//        }
//    }
//    .el-menu-item.is-active {
//        color: 灰色;
//        background-color: 红色;
//    }
//}

</style>
