// global.js
export default {
    //dev环境
    // base_url: "http://localhost:3000",
    // socket_url: "ws://127.0.0.1:3000/push-message",
    // base_upload_url: "http://localhost/base/file/upload",
    // preview_path_url: "http://localhost/images/",


    //test环境
    // base_url: "http://shengwei.51vip.biz/api",
    // socket_url: "ws://http://shengwei.51vip.biz/api/push-message",
    // base_upload_url: "http://localhost/base/file/upload",
    // preview_path_url: "http://123.60.55.61/images/",

    //prod环境
    base_url: "http://101.43.20.92/api",
    socket_url: "ws://101.43.20.92:3000/push-message",
}
