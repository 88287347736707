const token_key = "token";
const router_data = "router_data";
const left_menu = "left_menu";

export function setToken(token) {
    return sessionStorage.setItem(token_key, token);
}

export function getToken() {
    return sessionStorage.getItem(token_key);
}

export function removeToken() {
    return sessionStorage.removeItem(token_key);
}

export function setRouter(data) {
    return sessionStorage.setItem(router_data, data);
}

export function getRouter() {
    return sessionStorage.getItem(router_data);
}

export function removeRouter() {
    return sessionStorage.removeItem(router_data);
}


export function setLeftMenu(data) {
    return sessionStorage.setItem(left_menu, data);
}

export function getLeftMenu() {
    return sessionStorage.getItem(left_menu);
}

export function removeLeftMenu() {
    return sessionStorage.removeItem(left_menu);
}