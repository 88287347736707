<template>
    <div class="body_div">
        <div class="content_div">
            <el-card>
                <div style="font-size: 30px;height: 80px;line-height: 60px;font-weight: bold;">
                    智能行情分析系统
                </div>
                <el-tabs v-model="activeName">
                    <el-tab-pane label="账号登录" name="first"></el-tab-pane>
                </el-tabs>
                <br>
                <el-form ref="form" :model="data" :rules="rules" @submit.native.prevent>
                    <el-form-item icon="el-icon-search" prop="userName">
                        <el-input prefix-icon="el-icon-place" v-model="data.userName" placeholder="请输入用户名"
                                  @keyup.enter="handleLogin('form')"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input prefix-icon="el-icon-lock" v-model="data.password" placeholder="请输入密码" show-password
                                  @keyup.enter="handleLogin('form')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button style="width: 100%" type="success" @click="btnLogin('form')" native-type="submit('form')">登&emsp;录</el-button>
                    </el-form-item>
                </el-form>

                <el-link :underline="false">还没有账号？</el-link>
                <el-link type="success" @click="btnRegister">创建新账号</el-link>
                <br>
                <br>

            </el-card>
        </div>
    </div>
</template>
<script>
import {reqGetUserInfo, reqUpdateSysUsers, reqUsersLogin} from "@/api/base/Users";
import {setToken} from "@/utils/SessionStorage";

function getCurrentDay() {
    // 创建一个新的 Date 对象，它将被初始化为当前日期和时间
    const now = new Date();
    // 获取年份
    const year = now.getFullYear();
    // 获取月份（月份从0开始计数，所以需要加1）
    const month = now.getMonth() + 1;
    // 获取日期
    const date = now.getDate();
    // 输出年月日
    return year + "-" + formatZero(month) + "-" + formatZero(date);
}

function formatZero(nums) {
    let res = nums < 10 ? "0" + nums : nums;
    return res;
}

export default {
    name: "Login",
    async created() {
        await this.getIpInfo();
    },
    mounted() {
    },
    methods: {
        btnRegister() {
            this.$router.push("/register");
        },
        goIndex() {
            this.$router.push("/home/index");
        },
        //用户登录
        async btnLogin(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let {data} = await reqUsersLogin(this.data);
                    setToken(data);
                    let res = await reqGetUserInfo({});
                    sessionStorage.setItem("loginName", res.data.nickName);
                    sessionStorage.setItem("loginInfo", JSON.stringify(res.data));
                    this.$message.success("登录成功");
                    this.goIndex();
                    if (this.ipInfo.ip){
                        await reqUpdateSysUsers(this.ipInfo);
                    }
                }
            });
        },
        //获取ip信息
        async getIpInfo() {
            await this.getIp3();
            if (!this.ipInfo.ip) {
                await this.getIp2();
            }
        },
        //使用腾讯地图获取ip信息
        async getIp1() {
            let today = getCurrentDay();
            try {
                let response = await this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
                    key: "O3JBZ-W55C7-LB6XQ-PY67G-5OLOO-DQBIK", // 注意：这里使用key为键名
                    output: 'jsonp',
                });
                let ipaddr = response.result.ip;
                this.ipInfo.ip = ipaddr;
                if (ipaddr) {
                    this.$message.success("1~~~");
                    let ipAddress = `${response.result.ad_info.nation}-${response.result.ad_info.province}-${response.result.ad_info.city}`;
                    this.ipInfo = {ip: ipaddr, addr: ipAddress, getTime: today};
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getIp2() {
            let today = getCurrentDay();
            try {
                let response = await fetch('https://httpbin.org/ip');
                let data = await response.json();
                if (data.origin) {
                    //this.$message.success("2~~~");
                    this.ipInfo.ip = data.origin;
                    this.ipInfo.getTime = today;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async getIp3() {
            let today = getCurrentDay();
            try {
                let response = await fetch('http://ip-api.com/json?lang=zh-CN');
                let data = await response.json();
                if (data.status === "success") {
                    this.ipInfo.ip = data.query;
                    this.ipInfo.addr = data.regionName + "-" + data.city;
                    this.ipInfo.getTime = today;
                    //this.$message.success("3~~~");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    },
    data() {
        return {
            activeName: "first",
            total: 0,
            dataList: [],
            query: {},
            data: {},
            rules: {
                userName: [
                    {required: true, message: '请输入用户名', trigger: 'blur'},
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                ]
            },
            ipInfo: {
                ip: null,
                addr: null,
                getTime: null,
            },
        }
    }
}
</script>
<style scoped lang="scss">
.content_div {
    justify-content: center;
    width: 25%;
    //width: 480px;
    position: absolute;
    top: 30%;
    left: 35%;
}

.top_button {
    cursor: pointer;
}

.body_div {
    background-image: url("@/assets/bg.jpg");
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-color: #92a8a9;
}

.top_card {
    background-color: #77b9be;
    color: white;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
}
</style>
